var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "content" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "form-block" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "form" },
          [
            _vm.message
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-danger mb-4",
                    attrs: { role: "alert" }
                  },
                  [_vm._v(" " + _vm._s(_vm.message) + " ")]
                )
              : _vm._e(),
            _c("p", [_vm._v("Welcome Back, Please login to your account")]),
            _c("ValidationObserver", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "needs-validation",
                          attrs: { novalidate: "" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.login($event)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "E-mail",
                                  rules: "required|email"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.email,
                                                expression: "user.email"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-lg",
                                            class: {
                                              "is-invalid":
                                                v.invalid && v.validated
                                            },
                                            attrs: {
                                              type: "email",
                                              name: "email",
                                              placeholder: "Email"
                                            },
                                            domProps: { value: _vm.user.email },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.user,
                                                  "email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.errors[0]) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "Password", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(v) {
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.user.password,
                                                expression: "user.password"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-lg",
                                            class: {
                                              "is-invalid":
                                                v.invalid && v.validated
                                            },
                                            attrs: {
                                              type: "password",
                                              placeholder: "Password"
                                            },
                                            domProps: {
                                              value: _vm.user.password
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.user,
                                                  "password",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invalid-feedback text-left"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.errors[0]) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-end my-4" },
                            [
                              _c(
                                "p",
                                { staticClass: "mb-0 font-italic" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "text-underline",
                                      attrs: { to: "/forget-password" }
                                    },
                                    [_vm._v("Forgot Password?")]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-group d-flex justify-content-between"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    type: "submit",
                                    disabled: invalid || _vm.loading
                                  }
                                },
                                [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading,
                                        expression: "loading"
                                      }
                                    ],
                                    staticClass: "spinner-grow spinner-grow-sm",
                                    staticStyle: {
                                      width: "1.0625rem",
                                      height: "1.0625rem"
                                    },
                                    attrs: {
                                      role: "status",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _vm._v(" Login ")
                                ]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { to: "/signup" }
                                },
                                [_vm._v("Sign-up")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: require("./../../assets/images/logo.png"), alt: "BSH" }
        })
      ]),
      _c("div", { staticClass: "brand-logos d-flex" }, [
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/bosch-logo.png"),
              alt: "Bosch"
            }
          })
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/siemens-logo.png"),
              alt: "siemens"
            }
          })
        ]),
        _c("div", { staticClass: "logo" }, [
          _c("img", {
            attrs: {
              src: require("./../../assets/images/neff.png"),
              alt: "neff"
            }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("p", [
        _c(
          "a",
          {
            staticClass: "mr-3",
            attrs: {
              href: "https://www.bosch-home.co.uk/store/terms-of-website-use"
            }
          },
          [_vm._v("Term of use ")]
        ),
        _c(
          "a",
          {
            staticClass: "mr-3",
            attrs: {
              href: "https://www.bosch-home.co.uk/data-protection-information"
            }
          },
          [_vm._v("Data Protection Policy")]
        ),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href:
                "https://www.bosch-home.co.uk/metapages/imprint/report-a-vulnerability#tab-navigation"
            }
          },
          [_vm._v("Reporting vulnerabilities")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }