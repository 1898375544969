<template>
  <main class="content">
    <div class="container">
      <div class="form-block">
        <header>
          <div class="logo">
            <img src="./../../assets/images/logo.png"  alt="BSH"/>
          </div>
          <div class="brand-logos d-flex">
            <div class="logo">
              <img src="./../../assets/images/bosch-logo.png" alt="Bosch" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/siemens-logo.png" alt="siemens" />
            </div>
            <div class="logo">
              <img src="./../../assets/images/neff.png" alt="neff" />
            </div>
          </div>
        </header>
        <div class="form">
          <div v-if="message" class="alert alert-danger mb-4" role="alert">
            {{ message }}
          </div>
          <p>Welcome Back, Please login to your account</p>
          <ValidationObserver v-slot="{ invalid }">
            <form class="needs-validation" @submit.prevent="login" novalidate>
              <div class="form-group">
                <ValidationProvider
                  name="E-mail"
                  rules="required|email"
                  v-slot="v"
                >
                  <input
                    type="email"
                    name="email"
                    class="form-control form-control-lg"
                    placeholder="Email"
                    v-model="user.email"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }"
                  />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider name="Password" rules="required" v-slot="v">
                  <input
                    type="password"
                    class="form-control form-control-lg"
                    placeholder="Password"
                    v-model="user.password"
                    v-bind:class="{ 'is-invalid': v.invalid && v.validated }"
                  />
                  <div class="invalid-feedback text-left">
                    {{ v.errors[0] }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="d-flex justify-content-end my-4">
                <!-- <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="remember-me"
                />
                <label class="form-check-label" for="remember-me">Remember me</label>
              </div> -->
                <p class="mb-0 font-italic">
                  <router-link to="/forget-password" class="text-underline"
                    >Forgot Password?</router-link
                  >
                </p>
              </div>
              <div class="form-group d-flex justify-content-between">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="invalid || loading"
                >
                  <span v-show="loading" class="spinner-grow spinner-grow-sm" style="width:1.0625rem;height:1.0625rem;" role="status" aria-hidden="true"></span>
                  Login
                </button>
                <router-link to="/signup" class="btn btn-secondary"
                  >Sign-up</router-link
                >
              </div>
            </form>
          </ValidationObserver>
        </div>
        <footer>
          <p>
            <a href="https://www.bosch-home.co.uk/store/terms-of-website-use" class="mr-3">Term of use </a>
            <a href="https://www.bosch-home.co.uk/data-protection-information" class="mr-3">Data Protection Policy</a>
            <a target="_blank" href="https://www.bosch-home.co.uk/metapages/imprint/report-a-vulnerability#tab-navigation">Reporting vulnerabilities</a>
          </p>
        </footer>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Login",
  components: {},
  props: {},
  data: function () {
    return {
      user: {
        email: "",
        password: "",
      },
      message: "",
      loading: false,
    };
  },
  methods: {
    login: function () {
      this.loading = true;
      this.$store.dispatch("login", this.user).then(
        () => this.$router.push("/"),
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data.non_field_errors[0]) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>